import React, { useEffect, useState } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import ButtonSpinner from "../ButtonSpinner";
import MediaLibraryModal from "./MediaLibraryModal";
import { createStory } from "../../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateStories, updateStories } from "../../redux/actions";
import ImageEditor from "../ImageEditor";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import CameraModal from "./CameraModal";
// import AddAudioStoryModal from "./AddAudioStoryModal";
// import VideoRangePlayer from "../VideoRangePlayer";

const NewStoryModal = ({
  isOpen,
  data,
  category,
  toggle = () => {},
  onSuccess = () => {},
  onDismiss = () => {},
  isForConfirmation = true,
}) => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    text: "",
    storyMedia: {
      contentType: "",
      previewBlob: "",
      url: "",
      uploadData: null,
    },
    storyFor: "all", // enum=["all", "subscribers"]
    category: "general", // enum=["post", "live event", "vault", "ppv", "general"]
    postId: null,
    eventId: null,
    vaultId: null,
    ppvId: null,
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
    data: null,
  });
  const [warning, setWarning] = useState({});
  const [newBlobUrl, setNewBlobUrl] = useState(null);
  
  const { stories } = useSelector((state) => state?.storiesData || {});
  const [audioRange, setAudioRange] = useState([0, 15]);
  const [videoRange, setVideoRange] = useState([0, 15]);

  const getVideoRangeData=(data)=>{
    // console.log("range>>>>> 123",data);
    setVideoRange(data);
  }
  // console.log(videoRange)
  console.log(audioRange)
  const _resetState = () => {
    setNewBlobUrl(null);
    setFormFields({
      text: "",
      storyMedia: {
        contentType: "",
        previewBlob: "",
        url: "",
        uploadData: null,
      },
      audioUrl: "",
      storyFor: "all",
      category: "general",
      postId: null,
      eventId: null,
      vaultId: null,
      ppvId: null,
    });
    setAudioRange(null);
    setVideoRange(null);
    // audioRange(null);
    // videoRange(null);
    setErrors({});
    setIsDirty({});
    setLoading(false);
    setWarning({});
  };

  const _onDismiss = () => {
    setNewBlobUrl(null);
    _closeModal();
    onDismiss();
  };

  const _closeModal = () => {
    setNewBlobUrl(null);
    _resetState();
    toggle();
  };

  const _onChageFormField = (key = "", value = "") => {
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  const _onImageEditing = (key = "", value = "", blobBytes = null) => {
    setNewBlobUrl(value);
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      storyMedia: {
        ...prevFormFields.storyMedia,
        [key]: value,
        url: undefined,
        uploadData: blobBytes,
      },
    }));
  };

 

  const _renderPreview = (newFormFields) => {
    if (
      !newFormFields?.storyMedia?.previewBlob &&
      !newFormFields?.storyMedia?.url
    ) {
      return errorHandler();
    }
    switch (newFormFields?.storyMedia?.contentType) {
      case "image": {
        return (
          <img
            alt="Story Img"
            className="storyImg"
            src={
              formFields?.storyMedia?.url || formFields?.storyMedia?.previewBlob
            }
          />
        );
      }

      // case "video": {
      //   return (
      //     <CustomVideoPlayer
      //       src={
      //         formFields?.storyMedia?.url || formFields?.storyMedia?.previewBlob
      //       }
      //       hlsLink={formFields?.storyMedia?.hlsLink}
      //       controls
      //     />
      //   );
      // }

      case "video": {
        // console.log(formFields?.storyMedia?.hlsLink);
        return formFields?.storyMedia?.hlsLink ? (
          <CustomVideoPlayer
            key={formFields?.storyMedia?._id}
            src={formFields?.storyMedia?.url}
            hlsLink={formFields?.storyMedia?.hlsLink}
            duration={formFields?.storyMedia?.duration}
            controls
          />
        ) : 
        // warning?.video && !formFields?.storyMedia?.hlsLink ? (
         
        //   <VideoRangePlayer
        //     videoSrc={
        //       formFields?.storyMedia?.previewBlob || formFields?.storyMedia?.url
        //     }
        //     getVideoRange={getVideoRangeData}
            
        //   />
        // ) :
         (
          <video
            src={
              formFields?.storyMedia?.url || formFields?.storyMedia?.previewBlob
            }
            alt="preview-video"
            controls
          />
        );
      }

      default: {
        return <></>;
      }
    }
  };

  const _onChangeFile = (storyMedia) => {
    const newFormFields = { ...formFields };
    const contentType = storyMedia?.[0]?.contentType;
    if (contentType === "video") {
      // check video duraation
      if (storyMedia?.[0]?.duration > 15) {
        setWarning({
          video: "The video is too long and will be trimmed to 15s",
        });
      } else {
        setWarning({});
      }
    }

    newFormFields.storyMedia = { ...storyMedia?.[0] };
    setFormFields(newFormFields);
    _validateFormfields({ newFormFields, isDirty });
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty?.[key]) {
          switch (key) {
            case "storyMedia": {
              if (
                newFormFields?.[key]?.previewBlob?.trim().length ||
                newFormFields?.[key]?.url?.trim().length
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "text": {
              if (newFormFields?.[key]?.trim().length) {
                if (newFormFields?.[key]?.trim().length > 80) {
                  newErrors[key] = "*Maximum 80 characters are allowed.";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
              break;
            }

            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _markAllIsDirty = () => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    Object.keys(newFormFields).forEach((key) => {
      newIsDirty[key] = true;
    });
    setIsDirty(newIsDirty);
    return newIsDirty;
  };

  const _categoryIdType = (category) => {
    if (!category) return;

    switch (category) {
      case "post": {
        return "postId";
      }
      case "event":
      case "live event": {
        return "eventId";
      }
      case "vault": {
        return "vaultId";
      }
      case "ppv": {
        return "ppvId";
      }

      default:
        break;
    }
  };

  const _createPayload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const newFormFields = { ...formFields };
        const payload = {};

        payload["text"] = newFormFields?.text?.trim();
        payload["storyFor"] = newFormFields?.storyFor;

        let uploadedFile = {};
        if (
          newFormFields?.storyMedia?.previewBlob &&
          !newFormFields?.storyMedia?.url
        ) {
          uploadedFile = await uploadFileOnServer(
            [newFormFields?.storyMedia],
            true
          );
        }

        if (newFormFields?.storyMedia?.contentType === "image") {
          payload["previewImageUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
        } else if (newFormFields?.storyMedia?.contentType === "video") {
          payload["videoUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
          payload["duration"] = uploadedFile?.[0]?.duration;
          // payload["start"] = videoRange[0];
          // payload["end"] = videoRange[1];
        }
        if (newFormFields?.storyMedia?.isFromCamera) {
          payload["isFromCamera"] = true;
        }

        // if (newFormFields?.audioUrl) {
        //   payload["audioUrl"] = newFormFields.audioUrl;
        // }

        // Set isMerge if both image and audio exist
        if (
          newFormFields?.storyMedia?.contentType === "image" &&
          newFormFields?.audioUrl
        ) {
          delete payload["previewImageUrl"];
          payload["imageUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
          payload["audioUrl"] = newFormFields.audioUrl;
          payload["audioStart"] = audioRange[0];
          payload["audioEnd"] = audioRange[1];
          payload["isMerge"] = true;
        }
        // Set isMerge if both video and audio exist
        if (
          newFormFields?.storyMedia?.contentType === "video" &&
          newFormFields?.audioUrl
        ) {
          payload["videoUrl"] =
            uploadedFile?.[0]?.url || newFormFields?.storyMedia?.url;
          payload["duration"] = uploadedFile?.[0]?.duration;
          payload["audioUrl"] = newFormFields.audioUrl;
          payload["audioStart"] = audioRange[0];
          payload["audioEnd"] = audioRange[1];
          payload["start"] = videoRange[0];
          payload["end"] = videoRange[1];
          // payload["isMerge"] = true;
        }

        payload["category"] = category || "general";
        if (category && category !== "general") {
          payload[`${_categoryIdType(category)}`] = data?.id || data?._id;
        }

        resolve(payload);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = _markAllIsDirty();

      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      const payload = await _createPayload();

      // call post story api
      await createStory(payload);

      dispatch(updateStories([...stories, { isReady: false }]));
      // get and update stories on redux
      await getAndUpdateStories()(dispatch);

      showToast("Story upload in progress", "success");
      onSuccess();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      _closeModal();
    } finally {
      setLoading(false);
    }
    setNewBlobUrl(null);
  };

  const _toggleMediaLibraryModal = (isOpen = false, data) => {
    setMediaLibraryModal({ isOpen, data });
  };

  const _onDropFile = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      // Get the files that were dropped
      const files = e.dataTransfer.files;

      _toggleMediaLibraryModal(true, files);
    } catch (error) {
      console.log({ error });
    }
  };

  const _createStoryMediaDataByCategory = (category, data) => {
    if (!category || !data?.id) {
      return {
        contentType: "",
        previewBlob: "",
        url: "",
        uploadData: null,
      };
    }

    // each type of posted content might have different structure
    switch (category) {
      case "ppv":
      case "live event":
      case "event": {
        if (data?.coverImage) {
          return {
            contentType: "image",
            previewBlob: null,
            url: data?.coverImage,
            uploadData: null,
          };
        }
        break;
      }

      case "vault": {
        if (data?.preview) {
          return {
            contentType: "image",
            previewBlob: null,
            url: data?.preview,
            uploadData: null,
          };
        }
        break;
      }

      case "post": {
        return {
          contentType: "",
          previewBlob: null,
          url: "",
          uploadData: null,
        };
      }

      default: {
        return {
          contentType: "",
          previewBlob: "",
          url: "",
          uploadData: null,
        };
      }
    }
  };

  const _preFillData = (data) => {
    const newFormFields = { ...formFields };
    newFormFields.category = category || "general";
    if (category && category !== "general") {
      newFormFields[`${_categoryIdType(category)}`] = data?.id || data?._id;
    }
    newFormFields.storyMedia = _createStoryMediaDataByCategory(category, data);
    setFormFields(newFormFields);
  };

  useEffect(() => {
    if (isOpen && isForConfirmation) {
      _preFillData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isForConfirmation, data]);

  // Inside NewStoryModal, update the useEffect to handle blobUrl
  const handleCameraSave = (blobUrl, blob, mediaType) => {
    const newFormFields = { ...formFields };
    newFormFields.storyMedia = {
      contentType: mediaType, // Use the mediaType passed from CameraModal
      previewBlob: blobUrl,
      uploadData: blob,
      isFromCamera: true,
    };
    setFormFields(newFormFields);
  };
  // console.log("setFormFields", formFields);

  const handalAudioSave = (audioUrl) => {
    // console.log("audioUrl", audioUrl);
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      audioUrl: audioUrl, // This could be a playable preview URL (like an Object URL from the blob)
    }));
  };
  // console.log(formFields);
  const getRangeStartEnd = (data) => {
    // console.log("range>>>>>",data);
    setAudioRange(data);
  };

 

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Create Story {isForConfirmation ? " for this content!" : ""}
      </ModalHeader>

      <ModalBody
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          _onDropFile(e);
        }}
      >
        <div className="position-relative">
          <FormGroup>
            <Button className="d-none" disabled={loading} />

            {formFields?.storyMedia?.contentType ? (
              formFields?.storyMedia?.contentType === "image" ? (
                <div>
                  {newBlobUrl ? (
                    <Label className="storyImgUpload">
                      {_renderPreview(formFields)}
                    </Label>
                  ) : (
                    <ImageEditor
                      image={
                        formFields.storyMedia.previewBlob ||
                        formFields.storyMedia.url
                      }
                      _onImageEditing={_onImageEditing}
                    />
                  )}
                </div>
              ) : (
                <div style={{height: 400}} className="position-relative">
                  <Label className="storyImgUpload">
                    {_renderPreview(formFields)}
                  </Label>
                </div>
              )
            ) : (
              <Label className="storyImgUpload flex-sm-row">
                <div
                  className="uploadWrap"
                  onClick={() => _toggleMediaLibraryModal(true)}
                >
                  <img
                    src="/assets/img/upload.png"
                    alt="Upload"
                    className="uploadImg"
                    loading="lazy"
                  />
                  Upload Image/Video
                </div>

                <CameraModal onSave={handleCameraSave} />
              </Label>
            )}

            {errors?.storyMedia ? (
              <div className="validation-error">{errors?.storyMedia}</div>
            ) : null}
            {warning?.video ? (
              <>
                <p className="text-danger" style={{fontSize: 12}}>{warning.video}</p>
                {/* {!formFields?.storyMedia?.hlsLink ? (
                  <VideoRangePlayer
                    videoSrc={
                      formFields?.storyMedia?.previewBlob ||
                      formFields?.storyMedia?.url
                    }
                    videoRef={videoRef}
                  />
                ) : (
                  ""
                )} */}
              </>
            ) : null}
          </FormGroup>

          {/* {(formFields?.storyMedia?.previewBlob ||
            formFields?.storyMedia?.url) && (
            <FormGroup>
              <AddAudioStoryModal
                saveAudio={handalAudioSave}
                getRange={getRangeStartEnd}
              />
            </FormGroup>
          )} */}
        </div>

        <FormGroup>
          <Label>Caption</Label>
          <Input
            type="text"
            placeholder="Write caption..."
            value={formFields?.text}
            onChange={(e) => _onChageFormField("text", e.target.value)}
          />
          {errors?.text ? (
            <div className="validation-error">{errors?.text}</div>
          ) : (
            <></>
          )}
        </FormGroup>

        <FormGroup check className="radio">
          <CustomInput
            className="form-check-input"
            id={`new_story_subscribers`}
            type="radio"
            name="new_story"
            checked={formFields?.storyFor === "subscribers"}
            value={"subscribers"}
            onChange={() => _onChageFormField("storyFor", "subscribers")}
            label={"Subscribers Only"}
          />
          <CustomInput
            className="form-check-input"
            id={`new_story_all`}
            type="radio"
            name="new_story"
            checked={formFields?.storyFor === "all"}
            value={"all"}
            onChange={() => _onChageFormField("storyFor", "all")}
            label={"All (Non-subscribers will also be able to see this story)"}
          />

          
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          className="modalBtnCancel"
          onClick={() => (isForConfirmation ? _onDismiss() : _closeModal())}
        >
          Cancel
        </Button>

        <Button
          className="modalBtnSave"
          onClick={() => _onSubmit()}
          disabled={loading}
        >
          {loading ? <ButtonSpinner /> : null} Post
        </Button>
      </ModalFooter>

      <MediaLibraryModal
        isOpen={mediaLibraryModal.isOpen}
        toggle={() => _toggleMediaLibraryModal()}
        dropFileData={mediaLibraryModal.data}
        uploadContents={(contents) => {
          _toggleMediaLibraryModal();
          _onChangeFile(contents);
        }}
        isMultipleUpload={false}
        isMultipleImages={false}
        isMultipleVideos={false}
        isMultipleAudios={false}
        isVideoUpload={true}
        isAudioUpload={false}
        isImageUpload={true}
        isUploadLimit={true}
        videoLimit={1}
        imageLimit={1}
        uploadedFiles={[]}
        isWelcomeMessage={true}
      />
    </Modal>
  );
};

export default NewStoryModal;
